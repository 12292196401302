import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { withPermissionsAndRoles, withSession } from './session';
import { APP_ROLES } from './constants';

const TemplateManagerLL = lazy(() => import('./templateManager/templateManager'));
const MemberManagementLL = lazy(() => import('./members/members'));
const ClientProjectsListLL = lazy(() => import('./clientProjects/clientProjectsList'));
const AccessDeniedLL = lazy(() => import('./layout/accessDenied'));
const BannerMessageLL = lazy(() => import('./layout/bannerMessage'));
const RolesLL = lazy(() => import('./Roles/Roles'));
const ProjectsLL = lazy(() => import('./projects/projects'));
const ClientOrgLL = lazy(() => import('./Clients/ClientOrgs'));
const ProjectDetailsLL = lazy(() => import('./projects/projectDetails'));
const CertificatesLL = lazy(() => import('./certificates/certificates'));
const ClientProjectDetailLL = lazy(() => import('./clientProjects/clientProjectDetails'));
const RelatedGuidesLL = lazy(() => import('shared-one-ux-components/dist/contextualize/relatedGuides/relatedGuides'));
const ChangeHistoryLL = lazy(() => import('./projects/apiDiscovery/changeHistoryPage'));
const SIPartnerAdminViewLL = lazy(() => import('./systemIntegrators/siPartnersAdmin'));
const SIPartnersViewLL = lazy(() => import('./systemIntegrators/siPartners'));
const SiProjectDetailsLL = lazy(() => import('./projects/siProjectDetails'));
const SIPartnerProjectDetailsLL = lazy(() => import('./systemIntegrators/adminSiProjectDetails/siPartnerProjectDetails'));

const lazyLoadComponent = Component =>
  props => (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );

const TemplateManager = lazyLoadComponent(TemplateManagerLL);
const MemberManagement = lazyLoadComponent(MemberManagementLL);
const ClientProjectsList = lazyLoadComponent(ClientProjectsListLL);
const AccessDenied = lazyLoadComponent(AccessDeniedLL);
const BannerMessage = lazyLoadComponent(BannerMessageLL);
const Roles = lazyLoadComponent(RolesLL);
const Projects = lazyLoadComponent(ProjectsLL);
const ClientOrg = lazyLoadComponent(ClientOrgLL);
const ProjectDetails = lazyLoadComponent(ProjectDetailsLL);
const Certificates = lazyLoadComponent(CertificatesLL);
const ClientProjectDetail = lazyLoadComponent(ClientProjectDetailLL);
const RelatedGuides = lazyLoadComponent(RelatedGuidesLL);
const ChangeHistory = lazyLoadComponent(ChangeHistoryLL);
const SIPartnerAdminView = lazyLoadComponent(SIPartnerAdminViewLL);
const SIPartnersView = lazyLoadComponent(SIPartnersViewLL);
const SiProjectDetails = lazyLoadComponent(SiProjectDetailsLL);
const SIPartnerProjectDetails = lazyLoadComponent(SIPartnerProjectDetailsLL);

const BaseRoutes = ({ roles, session }) => {
  const routes = [];
  const location = useLocation();
  const { pathname } = location;

  if (roles.includes(APP_ROLES.CLIENT_ADMIN)) {
    routes.push(
      <Route key={"route-key-root-client-orgs"} exact path={"/"}><Redirect to="/client-orgs" /></Route>,
      <Route key={"route-key-client-orgs"} exact path={"/client-orgs"}
        render={(props) => (
          <ClientOrg
            {...props}
            session={session}
            role={session.role}
            roles={roles}
            ooid={session.organizationOID}
            orgName={session.organizationName}
          />
        )}
      />,
      <Route key={"route-key-client-projects"} exact path={"/client-projects"} component={ClientProjectsList} />,
      <Route key={"route-key-client-projects"} exact path={"/client-projects/:projectID"} component={ClientProjectDetail} />,
      <Route key={"route-key-si-partners"} exact path={"/si-partners"} component={SIPartnerAdminView} />,
      <Route key={"route-key-si-partner-projects"} exact path={"/si-partner-projects/:projectID"}
        render={() => (<SIPartnerProjectDetails />)}
      />
    );
  }
  if (roles.includes(APP_ROLES.TEMPLATE_ADMIN)) {
    routes.push(
      <Route key={"route-key-root-widgets"} exact path={"/"}><Redirect to="/template-manager" /></Route>,
      <Route key={"route-key-template-manager"} exact path={"/template-manager"} component={TemplateManager} />
    );
  }
  if (roles.includes(APP_ROLES.MEMBER_USER)) {
    routes.push(
      <Route key={"route-key-root-widgets"} exact path={"/"}><Redirect to="/members" /></Route>,
      <Route key={"route-key-members"} exact path={"/members"}
        render={(props) => (
          <MemberManagement
            {...props}
            role={session.role}
            aoid={session.associateOID}
            orgName={session.organizationName}
            isAdmin={false}
          />
        )}
      />
    )
  }
  if (roles.includes(APP_ROLES.PROJECT_USER)) {
    routes.push(
      <Route key={"route-key-root-projects"} exact path={"/"}><Redirect to="/projects" /></Route>,
      <Route key={"route-key-projects"} exact path={"/projects"}
        render={() => (
          <Projects
            givenName={session.given_name}
            orgName={session.organizationName}
          />
        )}
      />,
      <Route key={"route-key-members"} exact path={"/projects/:projectID"}
        render={(props) => (
          <ProjectDetails
            {...props}
            role={session.role}
            roles={roles}
            ooid={session.organizationOID}
            aoid={session.associateOID}
            orgName={session.organizationName}
          />
        )}
      />,
      <Route key={"route-key-members"} exact path={"/projects/:projectID/related-guides"}
        render={(props) => (
          <RelatedGuides {...props} />
        )}
      />,
      <Route key={"route-key-change-history"} exact path={"/projects/:projectID/change-history"}
        render={(props) => (
          <ChangeHistory {...props} />
        )}
      />,
      <Route key={"route-key-partners"} exact path={"/partners"} component={SIPartnersView} />,
      <Route key={"route-key-si-project-details"} exact path={"/si-projects/:projectID"}
        render={() => (<SiProjectDetails  memberType={session.memberType} />)}
      />
    )
  }
  if (roles.includes(APP_ROLES.CERTIFICATE_USER)) {
    routes.push(
      <Route key={"route-key-root-certificates"} exact path={"/"}><Redirect to="/certificate" /></Route>,
      <Route key={"route-key-certificates"} exact path={"/certificate"}
        render={(props) => (
          <Certificates
            {...props}
            role={session.role}
            roles={roles}
            ooid={session.organizationOID}
            aoid={session.associateOID}
            orgName={session.organizationName}
            email={session.email}
          />
        )}
      />
    );
  }
  if (roles.includes(APP_ROLES.SUPER_ADMIN)) {
    routes.push(
      <Route key={"route-key-root-widgets"} exact path={"/"}><Redirect to="/roles" /></Route>,
      <Route key={"route-key-template-roles"} exact path={"/roles"}
        render={(props) => (
          <Roles
            {...props}
            role={session.role}
            roles={roles}
            ooid={session.organizationOID}
            orgName={session.organizationName}
          />
        )}
      />
    );
  }


  routes.push(
    <Route key={"route-key-access-denied"} exact path={"/access-denied"}
      render={(props) => <AccessDenied {...props} roles={roles} />}
    />
  );
  routes.push(
    <Redirect key={"redirect-key-access-denied"} to={`/access-denied?error=${pathname === "/error"}`} />
  );
  routes.push(
    <Route key={"route-key-not-found"}
      render={(props) => (
        <BannerMessage {...props} title="404" subtitle="page not found" />
      )}
    />
  );
  return (
    <div className="framework-masthead__page"><Switch>{routes}</Switch></div>
  );
};

export default withSession(withPermissionsAndRoles(BaseRoutes));
